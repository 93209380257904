import dynamic from 'next/dynamic'
import { Suspense } from 'react'
import styled from "styled-components";
import Header from "../components/header";

const Benefits = dynamic(() => import('../components/benefits'))
const AppFeautures = dynamic(() => import('../components/appFeautures'))
const Services = dynamic(() => import('../components/services'))
const FAQ = dynamic(() => import('../components/questions'))
const Works = dynamic(() => import('../components/works'))
const Testimonials = dynamic(() => import('../components/testimonials'))

const Container = styled.div`
& >*{
  margin-bottom: 3% !important;
}
`
export default function Home() {
  return (
    <Container className="col-12 col-xxl-9 mx-sm-auto  text-center text-xl-start">
      <Header />
      <Suspense fallback={`Loading...`}>
        <Benefits />
      </Suspense>
      <Suspense fallback={`Loading...`}>
      </Suspense>
      <Suspense fallback={`Loading...`}>
        <AppFeautures />
      </Suspense>
      <Suspense fallback={`Loading...`}>
        <Services />
      </Suspense>
      <Suspense fallback={`Loading...`}>
        <Works />
      </Suspense>
      <Suspense fallback={`Loading...`}>
        <FAQ />
      </Suspense>
      <Suspense fallback={`Loading...`}>
        <Testimonials />
      </Suspense>
    </Container>
  );
}
